/*
* Application'style
*/

#header-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    .menu-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.5rem;
        height: 3rem;
        background-color: rgba(255, 255, 255, .1);
        padding: 5px;
        position: relative;
        z-index: 200;

        .line {
            display: block;
            width: 70%;
            height: 3px;
            background-color: #fff;
            transition: .8s;
            position: absolute;
            transform: rotate(0deg);

            &:nth-child(1) {
                top: 30%;
            }
            &:nth-child(2) {
                top: 50%;
            }
            &:nth-child(3) {
                top: 70%;
            }
        }

        &.open {
            .line {
                &:nth-child(1) {
                    top: 50%;
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    opacity: 0;
                }
                &:nth-child(3) {
                    top: 50%;
                    transform: rotate(-45deg);
                }
            }

            & + .link-container {
                display: flex;
                // width: 50vw;
                min-height: 24vh;
                position: relative;
                // top: 0;
                right: 0;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
                padding: 32px 15px;
                background: #2d4079;
                border-bottom: solid 2px rgba(255, 255, 255, .3);
                z-index: 100;
                opacity: 1;

                a {
                    margin-top: 10px
                }
            }
        }
    }

    .link-container {
        transition: .8s;
        height: 0px;
        overflow: hidden;
        opacity: 0;
    }
}


@media screen and (min-width: 768px) {
    #header-menu {
        .menu-btn {
            display: none;
        }

        .link-container {
            display: flex;
            justify-content: flex-end;
            opacity: 1;
            min-height: 2rem;

            a {
                display: block;
                min-width: 70px;
                margin-right: 10px;
                text-align: center;
            }
        }
    }
}
